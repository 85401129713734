import React, { Suspense, lazy, useEffect } from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from 'react-router-dom'
import AppRoute from './route-config'
import { useSelector } from 'react-redux'
import { constants } from 'utils/webclient'
import Wrapper from 'components/layouts/Wrapper'

const Home = lazy(() => import("project/views/home/home"))
const Hospitals = lazy(() => import("project/views/hospital"))
const HospitalDetail = lazy(() => import("project/views/hospital/hospital-detail"))
const Departments = lazy(() => import("project/views/department"))
const DepartmentDetail = lazy(() => import("project/views/department/department-detail"))
const Doctors = lazy(() => import("project/views/doctor"))
const InsuranceCompanies = lazy(() => import("project/views/insurance-companies"))
const Package = lazy(() => import("project/views/package"))
const PackagePlans = lazy(() => import("project/views/package/package-plans"))
const ComparePlans = lazy(() => import("project/views/package/compare-plans"))
const Files = lazy(() => import("project/views/files"))
const Children = lazy(() => import("project/views/children"))
const Vaccines = lazy(() => import("project/views/vaccines"))
const VideoPortal = lazy(() => import("project/views/video-portal"))
const Components = lazy(() => import("components"))
const Signin = lazy(() => import("project/views/login/sign-in"))
const Signup = lazy(() => import("project/views/login/sign-up"))
const Appointments = lazy(() => import("project/views/appointments"))
const Survey = lazy(() => import("project/views/pages/survey"))
const Visits = lazy(() => import("project/views/visits"))
const Chat = lazy(() => import("project/views/pages/chat"))
const Profile = lazy(() => import("project/views/profile/index"))
const NotFound = lazy(() => import("project/views/pages/not-found"))
const Medicines = lazy(() => import("project/views/medicines"))
const Appointment = lazy(() => import("project/views/appointment"))
const HealthForm = lazy(() => import("project/views/health-form"))

const PatientFile = lazy(() => import("project/views/home/patientFile"))
const DeepLink = lazy(() => import("project/views/deeplink"))
const Basket = lazy(() => import("project/views/basket"))
const Payment = lazy(() => import("project/views/payment"))
const VideoCall = lazy(() => import("project/views/pages/video-call"))
const Help = lazy(() => import("project/views/misc/help"))
const Faq = lazy(() => import("project/views/misc/faq")) 
const Terms = lazy(() => import("project/views/misc/terms")) 
const TermsOfUse = lazy(() => import("project/views/misc/terms-of-use"))
const Kvkk = lazy(() => import("project/views/misc/kvkk")) 

const Reports = lazy(() => import("project/views/reports"))
const ReportsDetail = lazy(() => import("project/views/reports/reports-detail"))
const DeepLinkSource = lazy(() => import("project/views/deeplink/source"))
const EcgGraph = lazy(() => import("project/views/ecg-graph"))

const Pharmacy = lazy(() => import("project/views/pharmacy"))
const Prescriptions = lazy(() => import("project/views/prescriptions"))

const Store = lazy(() => import("project/views/store"))

const Privileges = lazy(() => import("project/views/privileges"))

const CodeLotus = lazy(() => import("project/views/code-lotus/home"))
const CodeLotusVideos = lazy(() => import("project/views/code-lotus/videos"))
const CodeLotusPodcasts = lazy(() => import("project/views/code-lotus/podcasts"))
const CodeLotusBlog = lazy(() => import("project/views/code-lotus/blog"))
const MobileApp = lazy(() => import("project/views/deeplink/app"))

const MainRouter = () => {
    const member = useSelector(state => state.member)
    const isParent = Boolean(member?.profileId === member?.patientId)

    return (
        <Router>
            <Routes>
                <Route exact path='/' element={<AppRoute component={Home} />}  />
                <Route exact path='/hospitals' element={<AppRoute component={Hospitals} />} />
                <Route exact path='/hospital-detail' element={<AppRoute component={HospitalDetail} />} />
                <Route exact path='/departments' element={<AppRoute component={Departments} />} />
                <Route exact path='/department-detail' element={<AppRoute component={DepartmentDetail} />} />
                <Route exact path='/doctors' element={<AppRoute component={Doctors} />} />
                <Route exact path='/insurance-companies' element={<AppRoute component={InsuranceCompanies} />} />
                <Route exact path='/files' element={<AppRoute component={Files} />} />   
                <Route exact path='/vaccines' element={<AppRoute component={Vaccines} />} />
                <Route exact path='/video-portal' element={<AppRoute component={VideoPortal} />} />
                {constants?.isDevelopment && (
					<Route exact path='/components' element={<AppRoute component={Components} />} />
				)}
                <Route exact path='/appointments' element={<AppRoute component={Appointments} />} />
                <Route exact path='/survey' element={<AppRoute component={Survey} />} />
                <Route exact path='/visits' element={<AppRoute component={Visits} />} />
                <Route exact path='/chat' element={<AppRoute component={Chat} />} />
                <Route exact path='/profile' element={<AppRoute component={Profile} />} />
                <Route exact path='/not-found' element={<AppRoute component={NotFound} />} />
                <Route exact path='/medicines' element={<AppRoute component={Medicines} />} />
                <Route exact path='/appointment' element={<AppRoute component={Appointment} />} />
                <Route exact path='/health-form' element={<AppRoute component={HealthForm} />} />
                <Route exact path='/terms-of-use' element={<AppRoute component={TermsOfUse} />} />
                <Route exact path='/basket' element={<AppRoute component={Basket} />} />
                <Route exact path='/payment' element={<AppRoute component={Payment} />} />
                <Route exact path='/video-call' element={<AppRoute component={VideoCall} />} />
                <Route exact path='/help' element={<AppRoute component={Help} />} />
                <Route exact path='/faq' element={<AppRoute component={Faq} />} />
                <Route exact path='/terms' element={<AppRoute component={Terms} />} />
                <Route exact path='/kvkk' element={<AppRoute component={Kvkk} />} />
                <Route exact path='/reports' element={<AppRoute component={Reports} />} />
                <Route exact path='/reports-detail' element={<AppRoute component={ReportsDetail} />} />
                {isParent && (
				    <>
				    	<Route exact path='/children' element={<AppRoute component={Children} />} />
				    	<Route exact path='/packages' element={<AppRoute component={Package} />} />
				    	<Route exact path='/package-plans' element={<AppRoute component={PackagePlans} />} />
				    	<Route exact path='/compare-plans' element={<AppRoute component={ComparePlans} />} />
				    </> 
                )}
                <Route exact path='/pharmacy' element={<AppRoute component={Pharmacy} />} />
                <Route exact path='/prescriptions' element={<AppRoute component={Prescriptions} />} />
                <Route exact path='/store' element={<AppRoute component={Store} />} />
                <Route exact path='/privileges' element={<AppRoute component={Privileges} />} />
                <Route exact path='/mobile-app' element={<AppRoute component={MobileApp} />} />
                <Route exact path='/integration/deeplink' element={<Wrapper><DeepLinkSource /></Wrapper>} />
                <Route exact path='/patient-file' element={<AppRoute component={PatientFile} />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    )
}

const AuthRouter = () => {

    return (
        
        <Router>
            <Routes>
                <Route exact path='/store' element={<AppRoute component={Store} />} />
                <Route exact path='/' element={<AppRoute component={Signin} />} />
                <Route exact path='/sign-in' element={<AppRoute component={Signin} />} />
                <Route exact path='/sign-up' element={<AppRoute component={Signup} />} />
                <Route exact path='/deepLinkLogin' element={<Wrapper><DeepLink /></Wrapper>} />
                <Route exact path='/patient-file' element={<AppRoute component={PatientFile} />} /> 
                <Route exact path='/help' element={<AppRoute component={Help} />} />
                <Route exact path='/faq' element={<AppRoute component={Faq} />} />
                <Route exact path='/terms' element={<AppRoute component={Terms} />} />
                <Route exact path='/kvkk' element={<AppRoute component={Kvkk} />} />
                <Route exact path='/integration/deeplink' element={<Wrapper><DeepLinkSource /></Wrapper>} />
                <Route exact path='/external/ecg-graph/:id' element={<Wrapper><EcgGraph /></Wrapper>} />    
                <Route exact path='/mobile-app' element={<AppRoute component={MobileApp} />} />
                <Route path="*" element={<Navigate to="/sign-in" />} />
            </Routes>
        </Router>
    )
}

const CodeLotusRouter = () => {
    return (
        <Router>
            <Routes>
                <Route exact path='/code-lotus' element={<AppRoute component={CodeLotus} />}  />
                <Route exact path='/videos' element={<AppRoute component={CodeLotusVideos} />} />
                <Route exact path='/podcasts' element={<AppRoute component={CodeLotusPodcasts} />} />
                <Route exact path='/blog' element={<AppRoute component={CodeLotusBlog} />} />
                <Route exact path='/mobile-app' element={<AppRoute component={MobileApp} />} />
                <Route path="*" element={<Navigate to="/code-lotus" />} />
            </Routes>
        </Router>
    )
}

export {
    AuthRouter,
    MainRouter,
    CodeLotusRouter
}