function subscribe(eventName, listener) {
    document.addEventListener(eventName, listener);
}
  
function unsubscribe(eventName, listener) {
    document.removeEventListener(eventName, listener);
}
  
function publish(eventName) {
    const event = new CustomEvent(eventName);
    document.dispatchEvent(event);
}

function publishWithParams(eventName, params) {
    const event = new CustomEvent(eventName, params);
    document.dispatchEvent(event);
}

function getFilenameExtension(contentType) {
    const map = {
      'image/jpeg': '.jpeg',
      'image/pjpeg': '.jpeg',
      'image/png': '.png',
      'image/x-png': '.png',
      'application/pdf': '.pdf',
      'video/mp4': '.mp4',
      'application/octet-stream': '.mp4',
      "text/plain": ".txt"
    };
   
    return map[contentType] || '';
}

  
export { publish, subscribe, unsubscribe, publishWithParams, getFilenameExtension};