import Wrapper from 'components/layouts/Wrapper'
import FullPageSpinner from 'components/spinner/fullpage-spinner'
import React, { Suspense } from 'react'

const AppRoute = ({ component: Component, ...rest }) => (
    <Wrapper>
        <Suspense fallback={<FullPageSpinner />}>
            <Component {...rest} />
        </Suspense>
    </Wrapper>
)
  
export default AppRoute