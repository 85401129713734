import { useState, createContext, useEffect } from "react"

export const ThemeContext = createContext()

export function ThemeContextProvider({children}) {

    let defaultTheme = localStorage.getItem("theme") ? localStorage.getItem("theme") : 'theme-khc' 
    const [theme, setTheme] = useState(defaultTheme)
    const toggleTheme = (themeValue) => {
        localStorage.setItem("theme", themeValue)
        setTheme(themeValue)
    }
    
    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            { children }
        </ThemeContext.Provider>
    )
}
// lottie-codeLotus eklenecek.