import React, { useContext, useState, Fragment } from 'react'
import { Button } from 'components/button'
import { AppContext } from 'configuration/app-context'
import * as signalR from "@microsoft/signalr"
import { JitsiMeeting } from '@jitsi/react-sdk'
import { useEffect } from 'react'
import { constants } from 'utils/webclient'
import WebClient from 'utils/webclient'
import KHCLottie from 'components/spinner/KHCLottie'
import { setVideoCallSurveyState } from 'utils/redux-store'
import moment from 'moment';

var interval;

const VideoCallWindow = (props) => {

    const { translate, toastError, toastSuccess } = useContext(AppContext)
    const [jitsiApi, setjitsiApi] = useState(null)
    const [patient, setPatient] = useState(JSON.parse(sessionStorage.getItem("patient_data")))
    const [doctorAttended, setDoctorAttended] = useState(null)
    const [isNotify, setIsNotify] = useState(true)
    const [now, setNow] = useState(new Date().getTime())

    useEffect(() => {

        var connection = new signalR.HubConnectionBuilder()
            .withUrl(`${constants.baseURL}/vitalHub`)
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        connection.on("StartPatientAppointmentCall", (message) => {

            setDoctorAttended(message)
        });

        connection.on("endCall", (message) => {

            jitsiApi?.dispose();
            setDoctorAttended(null);
            props?.setVideCallUrl("");
            setVideoCallSurveyState({ vdeoCallGroupId: props?.videoCallUrl }) //doktor görüşmeyi tamamlandığında
        });

        connection.start().then((a) => {

            console.log("socket connected", a)

            var conId = connection.connectionId;
            var model = {
                userName: patient.protokolNo,
                connectionId: conId,
                currentRoom: props?.videoCallUrl,
            }

            let c = new WebClient()
            c.post("VideoCall/LoginVideoCall", model, false, true)
                .then(({ isSuccess, data }) => {

                    c.post("VideoCall/CheckPatientAppointmentCallSocket", { doctorCode: props?.doctorCode, roomName: props?.videoCallUrl, appointmentDate: props?.appointmentDate, patientName: patient.fullName }, false, true)
                        .then(({ isSuccess, data }) => {
                            if (isSuccess) {
                                setDoctorAttended(1)
                            }
                        })
                })

        }).catch((error) => {
            console.log(error)
        });

        interval = setInterval(() => {
            setNow(new Date().getTime())
        }, 1000)

        return () => {
            clearInterval(interval)
        }    
    }, []);

    const handleSendNotification = (appointmentId) => {
        let c = new WebClient()
        c.post("VideoCall/NotifyClinician", {appointmentId}, true, true, true)
            .then(({ isSuccess, data, exception }) => {
                if(isSuccess){
                    setIsNotify(false)
                    toastSuccess(translate("notify_sended_successful"))
                    clearInterval(interval)
                } else {
                    toastError(exception?.message ?? translate('unexpected_error_occurred'));
                }
            })
    }

    const appointmentDate = parseFloat(moment(props?.appointmentDate).format('x'))

    return (
        <div className="modal">
            <div className="modal-wrapper max-w-6xl">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="meeting">
                            {
                                doctorAttended != null ?
                                    <JitsiMeeting
                                        domain="meet.kochealthcare.org"
                                        roomName={props?.videoCallUrl}
                                        configOverwrite={{
                                            startWithAudioMuted: true,
                                            disableModeratorIndicator: true,
                                            startScreenSharing: false,
                                            enableEmailInStats: false,
                                            prejoinPageEnabled: true
                                        }}
                                        interfaceConfigOverwrite={{
                                            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
                                        }}
                                        userInfo={{
                                            displayName: patient.fullName
                                        }}
                                        onApiReady={(externalApi) => {

                                            setjitsiApi(externalApi);
                                            externalApi.addEventListener("videoConferenceLeft", () => {

                                                navigator.mediaDevices.getUserMedia({ audio: true, video: false })
                                                jitsiApi?.dispose();
                                                jitsiApi?.executeCommand('removeBreakoutRoom', { breakoutRoomJid: props?.videoCallUrl });
                                                props?.setVideCallUrl("");
                                                setDoctorAttended(null);
                                                setVideoCallSurveyState({ vdeoCallGroupId: props?.videoCallUrl }) //görüşmeden ayrıldığında
                                            });

                                            externalApi.on('videoMuteStatusChanged', function (event) {
                                                console.log(event);
                                                let cc = new WebClient()
                                                cc.post("VideoCall/UserInterfaceSetting", {
                                                    videoCallGroupId: props?.videoCallUrl,
                                                    settingType: 3,
                                                    value: !event.muted
                                                }, false, true)
                                                    .then(({ isSuccess, data }) => {

                                                        if (isSuccess) {
                                                        }
                                                    })
                                            });

                                            externalApi.on('errorOccurred', function (event) {
                                                if (event.error?.isFatal) {
                                                    let cc = new WebClient()
                                                    cc.post("VideoCall/ExceptionLog", {
                                                        videoCallGroupId: props?.videoCallUrl,
                                                        trace: event.error?.message,
                                                        page: event.error?.name,
                                                        type: event.error?.type,
                                                        userName: '',
                                                        userId: 0,
                                                        protocoleNo: patient.protokolNo
                                                    }, false, true)
                                                        .then(({ isSuccess, data }) => {

                                                            if (isSuccess) {
                                                            }
                                                        })
                                                }
                                            });

                                            let c = new WebClient()
                                            c.post("Appointment/OnlineAppointmentMeetngHasStart", { appointmentId: props.appointmentId, videCallGroupId: props.videoCallUrl, doctorCode: props.doctorCode }, false, true)
                                                .then(({ isSuccess, data }) => {

                                                })
                                        }}
                                        getIFrameRef={(iframeRef) => { iframeRef.style.height = '100%'; }}
                                    />
                                    : (
                                        <div className='flex flex-col items-center justify-center h-full gap-2'>
                                            <KHCLottie
                                                animationData='hourglass'
                                                lottieStyle='w-16 h-16'
                                            />
                                            <p className='text-center'>{translate('lobby_info')}</p>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            onClick={async () => {

                                jitsiApi?.executeCommand('removeBreakoutRoom', { breakoutRoomJid: props?.videoCallUrl });
                                jitsiApi?.dispose();

                                props?.setVideCallUrl("");
                                setDoctorAttended(null);
                                setVideoCallSurveyState({ vdeoCallGroupId: props?.videoCallUrl }) //görüşmeyi tamamlandığında

                                let c = new WebClient()
                                c.post("VideoCall/CallPatientReject", { videoCallGroupId: props?.videoCallUrl, protokolNo: patient.protokolNo }, false, true)
                                    .then(({ isSuccess, data }) => {
                                    })
                            }}
                            className="btn-gradient-secondary w-48"
                            label={translate("end_call")} />
                        
                        {
                            (doctorAttended == null && (now + 120000) > appointmentDate ) && isNotify && (
                                <Button
                                    label={translate("notify_to_staff")}
                                    className="btn btn-gradient-primary w-48"
                                    onClick={() => handleSendNotification(props?.appointmentId) }/>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoCallWindow