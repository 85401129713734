import WebClient, { constants } from 'utils/webclient'
import { store, setBasket, addToBasket, removeFromBasket } from 'utils/redux-store'
import * as rdd from 'react-device-detect';

export async function GetHospitalListForSelect(value = "id") {
    
    let languageId = store.getState().languageId;
    if (languageId == undefined) {
        languageId = 1;
    }
    let list = []
    let client = new WebClient()
    let a = await client.post("Common/HospitalList", {}, true)
    if (a?.data)
        list = a.data

        let b
        if (value == "code"){
             b = list.map((e) => { return { value: e.code, label: e.resources.filter(x => x.languageId == languageId)[0].name } })
        } else {
             b = list.map((e) => { return { value: e.id, label: e.resources.filter(x => x.languageId == languageId)[0].name } })
        }

    // if (languageId == 1) {
    //     var item = { value: 0, label: "Tümü" };
    //     b.splice(0, 0, item);
    // }
    // else {
    //     var item = { value: 0, label: "All" };
    //     b.splice(0, 0, item);
    // }

    return b
}

export async function FilterHospitalsByAppointmentType({appointmentTypeId = 0, departmentId = 0, doctorId = 0, hospitalPackageGradeId = 0, hospitalServiceExternalId = 0, insuranceId = 0}) {

    let list = []
    let client = new WebClient()
    let a = await client.post("Common/FilterHospitals", {
        doctorId,
        departmentId,
        appointmentTypeId,
        page: 0,
        pageSize: 0,
        search: "",
        hospitalPackageGradeId,
        hospitalServiceExternalId,
        insuranceId
    }, true)
    if (a?.data)
        list = a?.data.map((e) => {
            return {
                ...e,
                name: e.resource?.name ?? "",
                logo: e.resource?.logo ?? "",
                value: e.id,
                label: e.resource?.name ?? ""
            }
        })

    return list
}

export async function FilterDepartments({appointmentTypeId = 0, hospitalId = 0, doctorId = 0, hospitalPackageGradeId = 0, hospitalServiceExternalId = 0}) {

    let list = []
    let client = new WebClient()
    let a = await client.post("Common/FilterDepartments", {
        doctorId,
        hospitalId,
        appointmentTypeId,
        page: 0,
        pageSize: 0,
        search: "",
        hospitalPackageGradeId,
        hospitalServiceExternalId
    }, true)
    if (a?.data)
        list = a?.data.map((e) => {
            return {
                ...e,
                name: e.resource?.name ?? "",
                logo: e.resource?.logo ?? "",
                value: e.id,
                label: e.resource?.name ?? ""
            }
        })

    return list
}

export async function GetDepartmentList(hospitalId, getSingularDepartments = true) {

    let languageId = store.getState().languageId;
    if (languageId == undefined) {
        languageId = 1;
    }

    let list = []
    let client = new WebClient()
    let a = await client.post("Common/DepartmentList", {
        hospitalId,
        getSingularDepartments
    }, true)
    if (a?.data)
        list = a?.data.map((e) => {
            return {
                ...e,
                name: e.resource.name,
                value: e.id,
                label: e.resource.name,
            }
        })

    return list
}

export async function GetHospitalDepartmentListForSelect(hospitalId) {
    let languageId = store.getState().languageId;
    if (languageId == undefined) {
        languageId = 1;
    }
    let list = []
    let client = new WebClient()
    let a = await client.post("Common/DepartmentList", {
        hospitalId
    })
    if (a?.data)
        list = a.data

    const b = list.map((e) => {
        return {
            value: e.id,
            label: e.resources.filter(x => x.languageId == languageId).length > 0 ? e.resources.filter(x => x.languageId == languageId)[0].name : ""
        }
    })

    // if (languageId == 1) {
    //     var item = { value: 0, label: "Tümü" };
    //     b.splice(0, 0, item);
    // }
    // else {
    //     var item = { value: 0, label: "All" };
    //     b.splice(0, 0, item);
    // }

    return b
}
export async function GetGenderForSelect() {
    let list = []
    let client = new WebClient()
    let a = await client.post("Common/GenderList", {})
    if (a?.data)
        list = a.data

    const b = list.map((e) => { return { value: e.id, label: e.name } })
    return b
}

export class BasketService {
    client = new WebClient();

    member = store.getState().member

    languageId = store.getState().languageId;
    patientId = this.member.profileId != this.member.patientId ? this.member.profileId : this.member.patientId

    async getSummary() {
        const response = await this.client.post('Basket/BasketSummary', {
            languageId: this.languageId,
        }, true);
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
            setBasket(data);
        } else {
            setBasket({})
        }
        return response;
    }

    async getItems() {
        const response = await this.client.post('Basket/BasketItems', {
            languageId: this.languageId,
        }, true);
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
            setBasket(data);
        } else {
            setBasket({})
        }
        return response;
    }

    async addItem(productId, quantity, productType) {
        const response = await this.client.post('Basket/AddBasketItem', {
            productId,
            productType, // package: 1, device: 2, appointment: 3, upgrade: 4
            quantity,
        }, true);
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
            addToBasket(quantity);
        }
        return response;
    }

    async removeItem(productId, productType) {
        const response = await this.client.post('Basket/RemoveBasketItem', {
            languageId: this.languageId,
            productId,
            productType
        }, true);
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
            removeFromBasket({ productId, productType});
        }
        return response;
    }

    async removeBasket() {
        const response = await this.client.post('Basket/RemoveBasket', {
            languageId: this.languageId,
        }, true);
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
            // setBasket({ totalQuantity: 0 });
            this.getItems()
        }
        return response;
    }
}

export class PaymentService {
    #client = new WebClient();
    languageId = store.getState().languageId;
    patientId = store.getState().patient.id;

    async getInstallmentInfo({ binNumber, amount, productType }) {
        const response = await this.#client.post('Payment/BinInquiry', {
            binNumber: binNumber.split(' ').join(''),
            amount: amount.toString(),
            productType,
        });
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
        }
        return response;
    }

    async createPatientPayment({
        hospitalId,
        addressId,
        billingId,
        productId,
        productType,
        cardNumber,
        cardHolderName,
        year,
        month,
        cvc,
        installment,
        amount,
        basketItemId
    }) {
        const response = await this.#client.post('Payment/PatientPaymentCreating', {
            languageId: this.languageId,
            clientIp: '',
            hospitalId,
            addressId,
            billingId,
            productId,
            productType,
            cardHolderName,
            year,
            month,
            cvc,
            installment,
            amount: amount.toString(),
            cardNumber: cardNumber.split(' ').join(''),
            basketItemId
        }, true);
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
        }
        return response;
    }

    async sendSurveyIdByOrderId({ orderId }) {
        console.log("sendSurveyIdByOrderId-->", orderId)
		const response = await this.#client.post('Survey/SendSurveyIdByOrderId', {orderId}, true);
		const { isSuccess, data, exception } = response;

		if (isSuccess) {
		}
		return response;
	}
}

export class PackageService {
    #client = new WebClient();
    languageId = store.getState().languageId;
    patientId = store.getState().patient.id;

	async comparePlans({ packageGradeIds }) {
		const response = await this.#client.post(
			'Package/ComparePlans',
			{
				packageGradeIds,
			},
			false,
			false,
			false
		);
		const { isSuccess, data, exception } = response;

		if (isSuccess) {
		}
		return response;
	}

    async getPackageDetail({ packageId }) {
        const response = await this.#client.post(
            'Package/GetPackageDetail',
            {
                packageId,
            },
            false,
            false,
            false
        );
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
        }
        return response;
    }

    async getPackageGradeDetail({ packageGradeId }) {
        const response = await this.#client.post(
            'Package/GetPackageGradeDetail',
            {
                packageGradeId,
            },
            false,
            false,
            false
        );
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
        }
        return response;
    }

    async getPatientPackageDetail({ purchaseId }) {
        const response = await this.#client.post(
            'PatientHospitalPackage/GetPatientPackageDetail',
            { purchaseId },
            true,
            false,
            false
        );
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
        }
        return response;
    }

    async getPatientActivePackages() {
        const response = await this.#client.post(
            'PatientHospitalPackage/GetPatientActivePackages',
            {},
            true,
            false,
            false
        );
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
        }
        return response;
    }

    async getOtherPeopleInThePackage({ purchaseId }) {
        const response = await this.#client.post(
            'PatientHospitalPackage/GetOtherPeopleInThePackage',
            { purchaseId },
            true,
            false,
            false
        );
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
        }
        return response;
    }

    async verifyForAddPeopleToPackage({ smsCode, key }) {
        const response = await this.#client.post(
            'PatientHospitalPackage/VerifyForAddPeopleToPackage',
            { smsCode, key },
            true,
            false,
            false
        );
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
        }
        return response;
    }

    async addPeopleToPackage({ purchaseId, tcIdentityNumber, passportNumber, birthdate, relationType, verificationCodeReceiveType = 1 }) {
        const response = await this.#client.post(
            'PatientHospitalPackage/AddPeopleToPackage',
            { purchaseId, protocolNo: '', tcIdentityNumber, passportNumber, birthdate, relationType, verificationCodeReceiveType },
            true,
            false,
            false
        );
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
        }
        return response;
    }

    async addPeopleToPackageByRegisteringPatient({
        purchaseId,
        name,
        surname,
        genderShortCode,
        nationalityCode,
        tcIdentityNumber,
        passportNumber,
        phone,
        eMail,
        birthdate,
        relationType,
        verificationCodeReceiveType = 1
    }) {
        const response = await this.#client.post(
            'PatientHospitalPackage/AddPeopleToPackageByRegisteringPatient',
            {
                purchaseId,
                protocolNo: '',
                name,
                surname,
                genderShortCode,
                nationalityCode,
                tcIdentityNumber,
                passportNumber,
                phone,
                eMail,
                birthdate,
                relationType,
                verificationCodeReceiveType
            },
            true,
            false,
            false
        );
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
        }
        return response;
    }

    async removePeopleToPackage({ relationId }) {
        const response = await this.#client.post(
            'PatientHospitalPackage/RemovePeopleToPackage',
            relationId,
            true,
            false,
            false
        );
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
        }
        return response;
    }

    async checkUpRequest({hospitalPackageGradeId, hospitalServiceExternalId}){
        const response = await this.#client.post(
            'Patient/CheckUp/CallRequest',
            { hospitalPackageGradeId, hospitalServiceExternalId },
            true,
            false,
            false
        );
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
        }
        return response;
    }

/*     async makeAppointmentFromService({hospitalPackageGradeId, hospitalServiceExternalId}){
        const response = await this.#client.post(
            'Appointment/GetAppointmentItemsByPackage',
            { hospitalPackageGradeId, hospitalServiceExternalId },
            true,
            false,
            false
        );
        const { isSuccess, data, exception } = response;

        if (isSuccess) {
        }
        return response;
    } */
}

export class OfficialDocumentService {
	#client = new WebClient();
    #patientId = store.getState().patient?.id
	languageId = store.getState().languageId;
	applicationId = constants.applicationId;

	async filter({ categoryId, appointmentExternalId, appointmentId, addressId, productId, productType }) {
		const response = await this.#client.post('OfficialDocument/Filter', {
			categoryId,
			applicationId: this.applicationId
		});
		const { isSuccess, data, exception } = response;

		if (isSuccess) {
		}
		return response;
	}

	async getCategoryList() {
		const response = await this.#client.post('OfficialDocument/DocumentCategoryList', {});
		const { isSuccess, data, exception } = response;

		if (isSuccess) {
		}
		return response;
	}
}

export class OfficialDocumentServiceForPatient {
	#client = new WebClient();
    #patientId = store.getState().patient?.id
	languageId = store.getState().languageId;
	applicationId = constants.applicationId;

	async filter({ categoryId, appointmentExternalId, appointmentId, addressId, productId, productType }) {
		const response = await this.#client.post('OfficialDocument/PatientFilter', {
			categoryId,
			applicationId: this.applicationId,
            appointmentExternalId,
            appointmentId,
            addressId,
            productId,
            productType
		},true);
		const { isSuccess, data, exception } = response;

		if (isSuccess) {
		}
		return response;
	}
}

export class NotificationService {
	#client = new WebClient();
	#applicationId = constants.applicationId;
	#patientId = store.getState().patient.id;
	#user = rdd.useDeviceData();

	async registerDevice({ playerId, isPermissionGranted }) {
		const response = await this.#client.post('Common/RegisterDevice', {
			id: undefined,
			uniqueId: undefined,
			playerId,
			brand: this.#user?.device?.vendor,
			model: this.#user?.device?.model,
			osversion: this.#user?.os?.version,
			type: this.#user?.device?.type || this.#user?.os?.name,
			platform: this.#user?.browser?.name,
			patientId: this.#patientId,
			hasNotificationPermission: isPermissionGranted,
			isEmailSubscribed: undefined,
			isPushDisabled: undefined,
			isSmssubscribed: undefined,
			isSubscribed: isPermissionGranted,
			pushToken: undefined,
			registerDeviceApplications: [
				{
					applicationId: this.#applicationId,
					appVersion: undefined,
					buildNumber: undefined,
					isActive: isPermissionGranted,
				},
			],
		});
		const { isSuccess, data, exception } = response;

		if (isSuccess) {
		}
		return response;
	}

	async listNotifications({ playerId }) {
		const response = await this.#client.post(
			'Notification/NotificationList',
			{
				playerId,
			},
			true
		);
		const { isSuccess, data, exception } = response;

		if (isSuccess) {
		}
		return response;
	}
}
