import React from "react"
import { legacy_createStore as createStore, combineReducers } from 'redux'
import { toast } from "react-toastify"
import { publish } from "./global-events";
import ISessionSocket from "./session-broadcast";
import WebClient from 'utils/webclient'

let _ih = null;
let sessionSocket = null;

const member = (state = null, action) => {
    switch (action.type) {
        case "SET_MEMBER_DATA": {
            return {...state, ...action.payload}
        }
        case "LOGOUT": {
            return null
        }
        case "RESET_MEMBER_DATA": {
            return action.payload
        }
        default:
            return state;
    }
}

const language = (state = "TR", action) => {
    switch (action.type) {
        case "SET_LANGUAGE": {
            return action.payload
        }
        default:
            return state;
    }
}

const surveyAnsver = (state = false, action) => {
    switch (action.type) {
        case "SET_SurveyAnsver": {
            return action.payload
        }
        default:
            return state;
    }
}

const videoCall = (state = null, action) => {
    switch (action.type) {
        case "SET_VIDEO_CALL": {
            return action.payload
        }
        default:
            return state;
    }
}

const videoCallSurvey = (state = null, action) => {
	switch (action.type) {
		case 'SET_VIDEO_CALL_SURVEY': {
			return action.payload;
		}
		default:
			return state;
	}
}

const languages = (state = [], action) => {
    switch (action.type) {
        case "SET_LANGUAGES": {
            return action.payload
        }
        default:
            return state;
    }
}

const tokenObject = (state = {}, action) => {
    switch (action.type) {
        case "SET_TOKEN_OBJECT": {
            return action.payload
        }
        default:
            return state;
    }
}

const access_token = (state = null, action) => {
    switch (action.type) {
        case "SET_ACCESS_TOKEN": {
            return action.payload
        }
        default:
            return state;
    }
}

const nationalities = (state = [], action) => {
    switch (action.type) {
        case "SET_NATIONALITIES": {
            return action.payload
        }
        default:
            return state;
    }
}

const hospitals = (state = [], action) => {
    switch (action.type) {
        case "SET_HOSPITALS": {
            return action.payload
        }
        default:
            return state;
    }
}

const languageId = (state = 1, action) => {
    switch (action.type) {
        case "SET_LANGUAGE_ID": {
            return action.payload
        }
        default:
            return state;
    }
}

const patient = (state = null, action) => {
    switch (action.type) {
        case "SET_PATIENT_DATA": {
            return action.payload
        }
        default:
            return state;
    }
}

const patientHas = (state = null, action) => {
    switch (action.type) {
        case "SET_PATIENT_HAS_DATA": {
            return action.payload
        }
        default:
            return state;
    }
}

const hospital = (state = null, action) => {
    switch (action.type) {
        case "SET_HOSPITAL": {
            return action.payload
        }
        default:
            return state;
    }
}

const genders = (state = [], action) => {
    switch (action.type) {
        case "SET_GENDERS": {
            return action.payload
        }
        default:
            return state;
    }
}


const common = (state = { notificationState: 'unsupported' }, action) => {
	switch (action.type) {
		case 'SET_STATE': {
			return { ...state, ...action.payload };
		}
		default:
			return state;
	}
};

const basket = (state = {}, action) => {
	switch (action.type) {
		case 'SET_BASKET':
			return action.payload;
		case 'ADD_TO_BASKET':
			return { ...state, totalQuantity: state.totalQuantity + action.payload };
		case 'REMOVE_FROM_BASKET':
			const productToRemove = state.basketItems.find(
				(item) =>
					item.productId === action.payload.productId && item.productType === action.payload.productType
			);
			const basketItems = state.basketItems.filter((item) => item !== productToRemove);
			const { quantity, totalPrice } = productToRemove;
			return {
				...state,
				totalQuantity: state.totalQuantity - quantity,
				totalAmount: state.totalAmount - totalPrice,
				basketItems,
			};
		default:
			return state;
	}
};

const loading = (state = false, action) => {
	switch (action.type) {
		case 'SET_LOADING': {
			return action.payload;
		}
		default:
			return state;
	}
};

const alert = (state = false, action) => {
    switch (action.type) {
        case 'SET_ALERT': {
            return action.payload;
        }
        default:
            return state;
    }         
}

const combinedReducer = combineReducers({
    member,
    language,
    languages,
    surveyAnsver,
    tokenObject,
    access_token,
    nationalities,
    languageId,
    patient,
    patientHas,
    hospital,
    hospitals,
    genders,
    basket,
    common,
    videoCall,
    videoCallSurvey,
    loading,
    alert
})

export const store = createStore(
	combinedReducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export function setCommonState(payload) {
	store.dispatch({
		type: 'SET_STATE',
		payload,
	});
}

export function setVideoCallState(payload) {
    store.dispatch({
        type: 'SET_VIDEO_CALL',
        payload,
    });
}

export function setVideoCallSurveyState(payload) {
    store.dispatch({
        type: 'SET_VIDEO_CALL_SURVEY',
        payload,
    });
}

export function setBasket(payload) {
	store.dispatch({
		type: 'SET_BASKET',
		payload,
	});
}

export function addToBasket(payload) {
	store.dispatch({
		type: 'ADD_TO_BASKET',
		payload,
	});
}

export function removeFromBasket(payload) {
	store.dispatch({
		type: 'REMOVE_FROM_BASKET',
		payload,
	});
}

export function setLanguages(payload) {
    store.dispatch({
        type: "SET_LANGUAGES",
        payload
    });
}

export function setHospitals(payload) {
    store.dispatch({
        type: "SET_HOSPITALS",
        payload
    });
}

export function setLanguage(payload) {
    store.dispatch({
        type: "SET_LANGUAGE",
        payload
    });
}

export function setSurveyAnsver(payload) {
    store.dispatch({
        type: "SET_SurveyAnsver",
        payload
    });
}

export function setNationalities(payload) {
    store.dispatch({
        type: "SET_NATIONALITIES",
        payload
    });
}

export function setMemberData(payload) {
    sessionStorage.setItem("member_data", JSON.stringify(payload))
    store.dispatch({
        type: "SET_MEMBER_DATA",
        payload
    });
}

export function setPatientData(payload) {
    sessionStorage.setItem("patient_data", JSON.stringify(payload))
    store.dispatch({
        type: "SET_PATIENT_DATA",
        payload
    });
}

export function setPatientHasData(payload) {
    sessionStorage.setItem("patient_has_data", JSON.stringify(payload))
    store.dispatch({
        type: "SET_PATIENT_HAS_DATA",
        payload
    });
}

export function setLanguageId(payload) {
    store.dispatch({
        type: "SET_LANGUAGE_ID",
        payload
    });
}

export function setHospital(payload) {
    store.dispatch({
        type: "SET_HOSPITAL",
        payload
    });
}

export function setGenders(payload) {
    store.dispatch({
        type: "SET_GENDERS",
        payload
    });
}

export function setLoading(payload) {
    store.dispatch({
        type: "SET_LOADING",
        payload
    });
}

export function setAlert(payload) {
    store.dispatch({
        type: "SET_ALERT",
        payload
    });
}

export async function logout(deepLink = null) {

   try {
    await PatientLogout();
   } catch (error) {
    
   } finally {    
        DisconnectSessionSocket()
        // sessionStorage.clear()
        publish("onClearCookie")
        
        localStorage.removeItem("theme")
    
        store.dispatch({
            type: "RESET_MEMBER_DATA",
            payload: null
        });
    
        store.dispatch({
            type: "SET_PATIENT_DATA",
            payload: null
        });

        if(deepLink) {
            setTimeout(() => { window.location.href = deepLink}, 0)
        }


   }
}

export function setTokenObject(payload) {

    localStorage.setItem("access_token", payload.token)
    localStorage.setItem("expireDate", payload.expireDate)    

    store.dispatch({
        type: "SET_ACCESS_TOKEN",
        payload: payload.token
    });    

    store.dispatch({
        type: "SET_TOKEN_OBJECT",
        payload
    });
}

export function initIntl(intl) {
    // _ih = new IntlHelper(intl)
}

export function InitSessionSocket(intl) {
    sessionSocket = new ISessionSocket(intl)
}

export function ConnectSessionSocket() {
    sessionSocket.Connect()
}

export function DisconnectSessionSocket() {
    sessionSocket.Disconnect()
}

export function checkIntl() {
    return _ih == null
}

export function _fm(id, values = {}) {
    // return _ih.fm(id, values)
}

const toastOpts = {
    position: "bottom-center",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    draggable: false,
    progress: undefined,
    theme: "colored"
}

export function toastSuccess(message) {
    toast.success(message, toastOpts)
}

export function toastError(message) {
    toast.error(message, toastOpts)
}

export function toastSuccessLocalized(message) {
    toast.success(message, toastOpts)
}

export function toastErrorLocalized(message) {
    toast.error(message, toastOpts)
}


export function handleSwitchLanguage(languageId) {
    let member = store.getState().member;
    if (member != null) {
        let c = new WebClient();
        c.post("Common/ChangeLanguage", {
            guid: member.guid,
            sessionId: member.sessionId,
            patientId: member.patientId,
            profileId: member.profileId,
            languageId: languageId,
            userId: member.userId,
            createdWithDeepLink: false
        }, false, false, false)
    }
}

async function PatientLogout() {
    let c = new WebClient();
    const response = await c.post("Patient/Logout", {}, true, false, false)
    return response;
}