import React from 'react'
import classnames from "classnames"

function Input(props) {

    const mb = props?.marginBottom ?? "mb-4"

    return (
        <div className={"form-group " + mb}>
            {props.label && <label>{props.label}</label>}
            {
            props?.type == "tel" ?
            <input
                className={classnames("form-control ",
                    {
                        "has-error": props.error
                    })}
                value={props.value}
                name={props.name}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                onChange={(e) => { props.onChangeEvent ? props.onChangeEvent(e) : props.onChange(e.target.value) }}
                placeholder={props.placeholder}
                maxLength={props?.maxLength ?? 255}
                disabled={props?.disabled ?? false}
                readOnly={props?.readOnly ?? false}
                min={props.min}
                max={props.max}
                ref={props.innerRef}
                type="tel"
                inputMode="tel"
                pattern="[0-9]"  
            />            
            :
            <input
                className={classnames("form-control ",
                    {
                        "has-error": props.error
                    })}
                type={props.type}
                value={props.value}
                name={props.name}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                onChange={(e) => { props.onChangeEvent ? props.onChangeEvent(e) : props.onChange(e.target.value) }}
                placeholder={props.placeholder}
                maxLength={props?.maxLength ?? 255}
                disabled={props?.disabled ?? false}
                readOnly={props?.readOnly ?? false}
                min={props.min}
                max={props.max}
                ref={props.innerRef}
            />            
            }
            {
                props.error && (
                    <span className="error text-secondary-500 font-normal" style={{ fontSize: 11 }}>{props.error}</span>
                )
            }
        </div>
    );
}

export default Input;
