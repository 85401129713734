import React, { useEffect, useContext, useState } from 'react'
import { AppContext } from 'configuration/app-context'
import { Button } from 'components/button'
import IRadioBox from 'components/radiobox'
import { TextArea } from 'components/form-inputs'
import WebClient from 'utils/webclient';
import { constants } from 'utils/webclient';
import { setVideoCallSurveyState, toastSuccess } from 'utils/redux-store'
import { useSelector } from 'react-redux'
import ISpinner from "components/spinner/spinner";

const RatingForm = () => {

    const { translate, toastSuccess } = useContext(AppContext)
    const videoCallSurvey = useSelector(state => state.videoCallSurvey)
    const [ratingSurvey, setRatingSurvey] = useState(null);
    const [patientSurvey, setPatientSurvey] = useState(null);
    const [answerQuestion, setAnswerQuestion] = useState(null)
    const [answerOption, setAnswerOption] = useState([])
    const [loading, setLoading] = useState(true)
    const [disabled, setDisabled] = useState(true)

    function loadRatingForm() {

        let c = new WebClient();
        c.post("Survey/SurveyDetailBySurveyCode", { surveyCode: constants.Exp727SurveyCode }, true, false, false)
            .then(async ({ isSuccess, exception, data }) => {
                if (isSuccess) {
                    console.log(data);
                    setRatingSurvey(data)
                    setLoading(false)
                    //sonrasında hasta için PatientSurvey tablosuna bir kayıt atılır.
                    c.post("Survey/SavePatientSurveyBySurveyCode", { surveyCode: constants.Exp727SurveyCode, referenceNumber: videoCallSurvey.videoCallUrl, referenceType: 5 }, true, false, false)
                        .then(async ({ isSuccess, exception, data }) => {
                            if (isSuccess) {
                                console.log(data);
                                setPatientSurvey(data)
                            }
                        }).catch(() => {
                        })
                }
            }).catch(() => {
            })
    }

    function saveAnswer(value, questionId, isComplete,optionId) {

        let c = new WebClient();
        c.post("Survey/SavePatientSurveyDetail",
            {
                patientSurveyId: patientSurvey,
                questionId: questionId,
                optionId: optionId,
                optionValue: value,
                freeTextValue: value,
                isComplete: isComplete
            }, true, false, false)
            .then(async ({ isSuccess, exception, data }) => {
                if (isSuccess) {
                    console.log(data);
              
                }
            }).catch(() => {
            })
    }

    useEffect(() => {

        loadRatingForm();

    }, []);

    return (
        <div className='flex flex-col items-center'>
            {
                loading ? 
                    <ISpinner/>
                :
                <>
                    <h2 className='text-xl text-gray-800 font-medium mb-4'>{ratingSurvey?.title}</h2>
                    <div className='w-full mb-2'>
                        {
                            ratingSurvey?.groups[0]?.questions.map((e, i) => {
                                return (
                                    <div key={i}>
                                        {
                                            e.optionType === 'single' ?
                                                <div className='mb-2'>
                                                    <h3 className='text-gray-700 mb-1'>{e.title}</h3>
                                                    <div className="flex items-center mb-4 space-x-4">

                                                        {
                                                            e.options.map((a, b) => {
                                                                return (
                                                                    <IRadioBox
                                                                        value={answerOption.length === 0 ? '' : answerOption?.filter((t) => t.optionId === a.id)[0]?.isSelected}
                                                                        onChange={() => {
                                                                            setDisabled(false)
                                                                            var option = {
                                                                                optionId: a?.id,
                                                                                value: a?.title,
                                                                                isSelected: true,
                                                                            }

                                                                            setAnswerOption(opt => {
                                                                                let a = [...opt]
                                                                                let b = a.find((e) => e.optionId === option.optionId)
                                                                                if (b) {
                                                                                    a.splice(b, 1);
                                                                                } else {
                                                                                    a = [option]
                                                                                }

                                                                                return a;
                                                                            })

                                                                            saveAnswer(a.title, e.id, false, a.id)
                                                                        }}
                                                                        label={a.title}
                                                                        key={b}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                        {
                                            e.optionType === 'raiting' ?
                                                <div className='mb-2'>
                                                    <h3 className='text-gray-700 mb-1'>{e.title}</h3>
                                                    <form className="star-rating rating" onChange={(z) => {
                                                        setDisabled(false)
                                                        saveAnswer(z.target.value, e.id, false, null)
                                                    }}>
                                                        <input className="radio-input" type="radio" id={e.id + 5} name="star-input" value="5" />
                                                        <label className="radio-label" htmlFor={e.id + 5} title="5 stars">5 stars</label>

                                                        <input className="radio-input" type="radio" id={e.id + 4} name="star-input" value="4" />
                                                        <label className="radio-label" htmlFor={e.id + 4} title="4 stars">4 stars</label>

                                                        <input className="radio-input" type="radio" id={e.id + 3} name="star-input" value="3" />
                                                        <label className="radio-label" htmlFor={e.id + 3} title="3 stars">3 stars</label>

                                                        <input className="radio-input" type="radio" id={e.id + 2} name="star-input" value="2" />
                                                        <label className="radio-label" htmlFor={e.id + 2} title="2 stars">2 stars</label>

                                                        <input className="radio-input" type="radio" id={e.id + 1} name="star-input" value="1" />
                                                        <label className="radio-label" htmlFor={e.id + 1} title="1 star">1 star</label>
                                                    </form>
                                                </div>
                                                : ''
                                        }
                                        {
                                            e.optionType === 'free_text' ?
                                                <div className='mb-2'>
                                                    <h3 className='text-gray-700 mb-1'>{ratingSurvey?.groups[0]?.questions[3].title}</h3>

                                                    <TextArea
                                                        name=""
                                                        value={answerQuestion?.freeTextValue ?? ""}
                                                        onChange={(e) => {
                                                            setDisabled(false)
                                                            var freeTextAnswer = {
                                                                questionId: ratingSurvey?.groups[0]?.questions[3].id,
                                                                freeTextValue: e.target.value,
                                                            }
                                                            setAnswerQuestion(freeTextAnswer)
                                                        }}
                                                    />
                                                </div>
                                                : ''
                                        }
                                    </div>
                                )
                            })
                        }

                    </div>
                    <Button
                        onClick={() => {
                            saveAnswer(answerQuestion?.freeTextValue, answerQuestion?.questionId ?? 0, true, null);
                            setVideoCallSurveyState(null)
                            toastSuccess(translate("form_submitted_successfully"))
                        }}
                        className='btn btn-gradient-primary w-2/3'
                        label={translate("evalute")} 
                        disabled={disabled}/>
                </>
            }
        </div>
    )
}

export default RatingForm