import moment from "moment"
import { createContext } from "react"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"
import Swal from 'sweetalert2'
import ISessionSocket from "utils/session-broadcast"


const toastOpts = {
    position: "bottom-center",
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    draggable: false,
    progress: undefined,
    theme: "colored"
}

// const swalWithBootstrapButtons = Swal.mixin({
//     customClass: {
//         confirmButton: 'btn btn-success',
//         cancelButton: 'btn btn-danger'
//     },
//     buttonsStyling: false
// })

export const AppContext = createContext()

export function AppContextProvider({children}) {

    const intl = useIntl()
    let sessionSocket = null

    return (
        <AppContext.Provider 
        value={{
            translate: (e, values = {}) => {
                return intl.formatMessage({id: e, defaultMessage: e}, values)
            },
            dateFormat: (date, format = "DD.MM.YYYY") => {
                return moment(date).format(format)
            },
            toastError: (e) => {
                toast.error(e, toastOpts)
            },
            toastWarning: (e) => {
                toast.warning(e, toastOpts)
            },
            toastSuccess: (e) => {
                toast.success(e, toastOpts)
            },
            showDialog: (
                title, 
                text, 
                icon, 
                showCancelButton, 
                confirmButtonText, 
                cancelButtonText, 
                onConfirm = () => {}, 
                onCancel = () => {}, 
                html,
                cancelButtonCss = "btn btn-danger", 
                showDenyButton = false, 
                denyButtonText = "Deny",
                onDeny = () => {}
            ) => {
                Swal.fire({
                    title: title,
                    text: text,
                    icon: icon,
                    showDenyButton: showDenyButton,
                    showCancelButton: showCancelButton,
                    confirmButtonText: confirmButtonText,
                    cancelButtonText: cancelButtonText,
                    html: html,
                    denyButtonText: denyButtonText,
                    reverseButtons: true,
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: cancelButtonCss,
                        denyButton: 'btn btn-success'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        onConfirm()
                    } else if (result.isDenied) {
                        onDeny()
                    } else {
                        onCancel()
                    }
                })
            },
            initSessionSocket: () => {
                sessionSocket = new ISessionSocket(intl);
                sessionSocket.Connect()
            },
            disconnectSessionSocket: () => {
                sessionSocket.Disconnect()
            }
        }}
        >
            {children}
        </AppContext.Provider>
    )
}