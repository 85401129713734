import Axios from 'axios';
import { toast } from 'react-toastify';
import * as Redux from './redux-store';
import LogoKhc from "assets/img/KH-logo.png"
import LogoCodeLotus from "assets/img/code-lotus-logo.png"
import BadgeKhc from "assets/img/badge-khc.svg"
import BadgeCodeLotus from "assets/img/badge-code-lotus.svg"
import { TOTP } from "totp-generator"

let access_token = null;

class WebClient {

    async CreateConfig(multipart = false) {
        await this.getToken()
        const str = Redux.store.getState()
        const otp_code = await this.GetOtpCode()
        let config = {
            baseURL: constants.baseURL,
            headers: {
                "userIdentifier": str?.member?.id ?? null,
                "languageId": str?.languageId ?? 1,
                "applicationCode": constants.applicationCode
            }
        }

        if (str?.access_token) {
            config.headers["Authorization"] = `Bearer ${str.access_token}`
        }

        if (multipart) {
            config.headers["Content-Type"] = "multipart/form-data"
        }

        if (str?.member?.profileId) {
            config.headers["profileId"] = str?.member?.profileId
            config.headers["patientId"] = str?.member?.patientId
            config.headers["sessionId"] = str?.member?.sessionId
        }

        config.headers["X-Client-Token"] = otp_code

        return config;
    }

    postWithParams = async (path, data, params, addProfile = false, toastSuccess = true, toastError = true) => {
        let config = await this.CreateConfig()
        const str = Redux.store.getState()

        let d = { ...data }
        if (addProfile) {
            d = {
                profile: {
                    guid: str.member?.guid ?? "",
                    sessionId: str.member?.sessionId ?? "",
                    patientId: str.member?.patientId ?? "",
                    profileId: str.member?.profileId ?? "",
                    userId: str.member?.userId ?? "",
                },
                data: data,
            }
        }

        const axios = Axios.create(config)
        var realPromise = axios.post(path, d, { params }, toastSuccess, toastError);
        return this.createProxyPromise(realPromise, toastSuccess, toastError);
    }

    post = async (path, data, addProfile = false, toastSuccess = true, toastError = true) => {
        let config = await this.CreateConfig()
        const str = Redux.store.getState()

        let d = { ...data }
        if (addProfile) {
            d = {
                profile: {
                    guid: str.member?.guid ?? "",
                    sessionId: str.member?.sessionId ?? "",
                    patientId: str.member?.patientId ?? "",
                    profileId: str.member?.profileId ?? "",
                    userId: str.member?.userId ?? ""
                },
                data: data
            }
        }

        const axios = Axios.create(config)
        var realPromise = axios.post(path, d, toastSuccess, toastError);
        return this.createProxyPromise(realPromise, toastSuccess, toastError);
    }

    put = async (path, data, toastSuccess = true, toastError = true) => {
        let config = await this.CreateConfig()
        const str = Redux.store.getState()
        let d = { ...data }
        const axios = Axios.create(config)
        var realPromise = axios.put(path, d, toastSuccess, toastError);
        return this.createProxyPromise(realPromise, toastSuccess, toastError);
    }

    get = async (path, toastSuccess = true, toastError = true) => {
        let config = await this.CreateConfig()
        const str = Redux.store.getState()
        const axios = Axios.create(config)
        var realPromise = axios.get(path, toastSuccess, toastError);
        return this.createProxyPromise(realPromise, toastSuccess, toastError);
    }

    delete = async (path, data, toastSuccess = true, toastError = true) => {
        let config = await this.CreateConfig()
        const str = Redux.store.getState()
        let d = { ...data }
        const axios = Axios.create(config)
        var realPromise = axios.delete(path, d, toastSuccess, toastError);
        return this.createProxyPromise(realPromise, toastSuccess, toastError);
    }

    postFormData = async (path, data, toastSuccess = true, toastError = true) => {
        let config = await this.CreateConfig(true)
        const axios = Axios.create(config)
        var realPromise = axios.post(path, data, toastSuccess, toastError);
        return this.createProxyPromise(realPromise, toastSuccess, toastError);
    }

    putFormData = async (path, data, toastSuccess = true, toastError = true) => {
        let config = await this.CreateConfig(true)
        const axios = Axios.create(config)
        var realPromise = axios.put(path, data, toastSuccess, toastError);
        return this.createProxyPromise(realPromise, toastSuccess, toastError);
    }

    getToken = async () => {
        const str = Redux.store.getState()
        const tokenObject = str.TokenObject

        let token_request = {
            token: "",
            expire_date: ""
        }

        if (tokenObject?.expireDate) {
            token_request.expire_date = tokenObject?.expireDate ?? ""
            token_request.token = tokenObject.token
        }

        let cnfg = { baseURL: "/base/" };
        const axios = Axios.create(cnfg);

        const response = await axios.post("token.json", token_request).catch(() => { })
        // if (response === undefined) Redux.toastError(Redux._fm("connection_error"))
        if (response.status == 200) {
            const data = response.data
            if (data.success) {
                Redux.setTokenObject(data.data)
            }
        }
    }

    welcome = async () => {
        const str = Redux.store.getState()
        const tokenObject = str.TokenObject

        let request = {
            token: "",
            expire_date: "",
            languageId: str.languageId,
            username: localStorage.getItem("username") ?? "",
            password: localStorage.getItem("password") ?? ""
        }

        if (tokenObject?.expireDate) {
            request.expire_date = tokenObject?.expireDate ?? ""
            request.token = tokenObject.token
        }

        let cnfg = { baseURL: "/base/" };
        const axios = Axios.create(cnfg);

        const response = await axios.post("welcome.json", request).catch(() => { })
        // if (response === undefined) Redux.toastError(Redux._fm("connection_error"))
        if (response.status == 200) {
            Redux.setLanguages(response.data.data?.languages ?? [])
            Redux.setHospitals(response.data.data?.hospitals ?? [])
            if (response.data.data?.nationalities) {
                let a = response.data.data.nationalities.map((e) => {
                    return {
                        ...e,
                        value: e.id,
                        label: e.name
                    }
                })
                Redux.setNationalities(a)
            }
            if (response.data.data?.genders) {
                let a = response.data.data.genders.map((e) => {
                    return {
                        ...e,
                        value: e.id,
                        label: e.name
                    }
                })
                Redux.setGenders(a)
            }
            constants.baseURL = response.data.data.base_url
            constants.imagePATH = response.data.data.base_image_url
            constants.isDevelopment = response.data.data.isDevelopment
            constants.siteUrl = response.data.data.base_site_url

            if (response.data?.data?.token) Redux.setTokenObject(response.data.data.token)
        }
    }

    insertPatient = async (data, toastSuccess = true, toastError = true) => {

        let cnfg = { baseURL: "/base/" };
        const axios = Axios.create(cnfg);
        var realPromise = axios.post("insertpatient", data, toastSuccess, toastError);
        return this.createProxyPromise(realPromise, toastSuccess, toastError);
    }
    identitysharingsystemquery = async (data, toastSuccess = true, toastError = true) => {

        let cnfg = { baseURL: "/base/" };
        const axios = Axios.create(cnfg);
        var realPromise = axios.post("identitysharingsystemquery", data, toastSuccess, toastError);
        return this.createProxyPromise(realPromise, toastSuccess, toastError);
    }
    createsessionbykey = async (data, toastSuccess = true, toastError = true) => {

        let cnfg = { baseURL: "/base/" };
        const axios = Axios.create(cnfg);
        var realPromise = axios.post("createsessionbykey", data, toastSuccess, toastError);
        return this.createProxyPromise(realPromise, toastSuccess, toastError);
    }
    patientlogin = async (data, toastSuccess = true, toastError = true) => {

        let cnfg = { baseURL: "/base/" };
        const axios = Axios.create(cnfg);
        var realPromise = axios.post("patientlogin", data, toastSuccess, toastError);
        return this.createProxyPromise(realPromise, toastSuccess, toastError);
    }

    createProxyPromise(realPromise, toastSuccess, toastError) {
        var _self = this;
        var proxyPromise = new Promise(function (resolve, reject) {
            realPromise.then(function (data) {
                // console.log(data)
                _self.responseHandler(data, resolve, reject, toastSuccess, toastError);
            }).catch(error => {
                console.error(error)
                _self.errorHandler(error, reject);
            })
        });
        return proxyPromise;
    }

    responseHandler = async (response, callback, reject, toastSuccess, toastError) => {
        // if (toastSuccess && response?.data?.isSuccess) {

        // }
        if (toastError && !response?.data?.isSuccess) {
            // let msg = response?.data?.exception?.message ?? "Unauthorized";
            // Redux.toastError(Redux._fm(msg))
        }

        if (response.data.exception?.message == "InvalidSessionProfile") {
            // Redux.toastErrorLocalized("your_session_has_expired_or_ended_please_try_logging_in_again")
            // Redux.logout()
        }

        callback(response.data);
    }

    errorHandler = async (error, reject) => {
        if (error.response !== undefined && error.response.status === 401) {
            toast.warning(error.response?.data?.exception?.message)
            Redux.logout()
        } else if (error.response !== undefined && error.response.status === 405) {
            // Redux.toastError(Redux._fm("invalid_request_method"))
        } else if (error.response !== undefined && error.response.status === 403) {
            // toast.error(error.response.data.message)
        } else if (error.response !== undefined && error.response.status === 404) {
            toast.warning("Endpoint not found!")
        } else {
            // Redux.toastError(Redux._fm("connection_error"))
            reject(error);
        }
    }

    GetOtpCode() {
        const e = new Date().setUTCHours(0,0,0,0);
        const d = new Date().getTime()
        const { otp, expires } = TOTP.generate(process.env.REACT_APP_TS, { period: 1, digits: 8, algorithm: "SHA-1", timestamp: d });  
        return `${otp}.${d-e}`
    }

}

export function setAccessToken(d) {
    access_token = d;
}

export function getAccessToken(d) {
    return access_token
}

export const constants = {
    // spinner: require("../assets/pulse.svg"),
    baseURL: "https://localhost:44302/",
    imagePATH: "https://kochealthcare.blob.core.windows.net/",
    siteUrl: "https://localhost:44305",
    applicationId: 3,
    applicationCode: "APP03",
    isDevelopment: false,
    recapthcaSiteKey: "6LcMYJ4lAAAAAC1mF0D64quHgD_UE7d1QT-ZaGSM",
    recapthcaApiKey: "6LcMYJ4lAAAAAL9zzqHxSxX4LeYS0j9LC-kjVQwm",
    Exp727SurveyCode: "724EXP",
    orderStates: {
        completed: 0,
        approved: 1,
        pending: 2,
        canceled: 3,
        received: 4,
        refunded: 5
    },
    paymentTypes: {
        package: 1,
        individualPayment: 2,
        provision: 3,
        paymentReceived: 4,
    },
    interviewTypes: {
        physical: 2,
        online: 3,
        tyto: 4
    },
    serviceUsageTypes: {
        appointment: 1,
        emergenyCall: 2,
        checkUp: 3,
        ambulance: 4,
        transfer: 5,
    },
    contracts: {
        kvkk: { key: 'kvkk', value: 'KVKK' },
        etk: { key: 'etk', value: 'ETK' },
        clarification: { key: 'clarification', value: 'AYDINLATMA' },
        preliminaryInfo: { key: 'preliminaryInfo', value: 'ÖN BİLGİLENDİRME' },
        privacyPolicy: { key: 'privacyPolicy', value: 'GİZLİLİK POLİTİKASI' },
        distanceSales: { key: 'distanceSales', value: 'MESAFELİ SATIŞ SÖZLEŞMESİ' },
        consent: { key: 'consent', value: 'ONAM FORMU' },
        conditions: { key: 'conditions', value: 'KULLANIM KOŞULLARI' },
    },
    theme: [
        {
            title: "Koç Healthcare",
            name: "theme-khc",
            logo: LogoKhc,
            badge: BadgeKhc

        },
        {
            title: "Code Lotus",
            name: "theme-codeLotus",
            logo: LogoCodeLotus,
            badge: BadgeCodeLotus
        }
    ]
}

export default WebClient
