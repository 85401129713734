import { useState, createContext, useEffect } from "react"
import { IntlProvider } from "react-intl"
import WebClient from 'utils/webclient'
import { useSelector } from 'react-redux'

import { Turkish } from 'flatpickr/dist/l10n/tr'
import { english } from 'flatpickr/dist/l10n/default'

import tr from "components/translate/tr.json"
import en from "components/translate/en.json"

import * as Redux from "utils/redux-store"
import {handleSwitchLanguage} from "utils/redux-store"

import moment from "moment"
import "moment/locale/tr"
import "moment/locale/en-gb"

export const IntlContext = createContext()

export function IntlContextProvider({children}) {
    const [langKey, setLangKey] = useState(localStorage.getItem("language") != undefined ? localStorage.getItem("language") : -1)
    const [language, setLanguage] = useState(localStorage.getItem("language") != undefined ? localStorage.getItem("language") : "TR")

    useEffect(() => {
        if (language == "TR") {
            moment.locale("tr")
        } else {
            moment.locale("en-gb")
        }
    }, [language])

    return (
        <IntlContext.Provider 
            value={{
                language: language,
                flatpickrLocale: language == "EN" ? english : Turkish,
                switchLanguage: (lang) => {

                    if (lang.code == "TR") {
                        moment.locale("tr")
                    } else {
                        moment.locale("en-gb") 
                    }

                    localStorage.setItem("language", lang.code)
                    localStorage.setItem("languageId", lang.id)
                    Redux.setLanguageId(lang.id)
                    Redux.setLanguage(lang.code)

                    setLanguage(lang.code)
                    setLangKey(lang.id)

                    handleSwitchLanguage(lang.id)
                }
            }}
        >
            <IntlProvider
                key={langKey}
                locale={language}
                messages={language == "EN" ? en : tr}
                defaultLocale="TR">
                    {children}
                </IntlProvider>
        </IntlContext.Provider>
    )
}