import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment } from 'react'

export default function Modal({
	isShow = false,
	isShowButtons = false,
	title,
	description,
	isApproveButtonDisabled,
	approveButtonTitle,
	rejectButtonTitle,
	children,
	bodyClassName,
	onClose = () => {},
	onApprove = () => {},
	onReject = () => {},
}) {
	const closeModal = () => {
		onClose()
	}

	return (
		<Transition appear show={isShow} as={Fragment}>
			<Dialog as='div' className='relative z-10' onClose={closeModal}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black bg-opacity-25' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel
								className={classNames(
									'w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all',
									bodyClassName
								)}
							>
								{title && (
									<Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
										{title}
									</Dialog.Title>
								)}

								{description && (
									<div className='mt-2'>
										<p className='text-sm text-gray-500'>{description}</p>
									</div>
								)}

								{children}

								{isShowButtons && (
									<div className='mt-4'>
										<div className='flex justify-center gap-4'>
											<button
												className='btn btn-outline'
												onClick={() => {
													onReject()
												}}
											>
												{rejectButtonTitle}
											</button>

											<button
												disabled={isApproveButtonDisabled}
												className='btn btn-gradient-primary'
												onClick={() => {
													onApprove()
												}}
											>
												{approveButtonTitle}
											</button>
										</div>
									</div>
								)}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
