import KHCLottie from './KHCLottie';

export default function IBigSpinner({ size = 'md' }) {
	// size: "sm" | "md" | "lg"

	if (size === 'sm') {
		return (
			<KHCLottie lottieStyle='absolute w-16 h-16 left-[calc(50%-theme(spacing[8]))] top-[calc(50%-theme(spacing[8]))]' />
		);
	}

	if (size === 'md') {
		return (
			<KHCLottie lottieStyle='absolute w-20 h-20 left-[calc(50%-theme(spacing[10]))] top-[calc(50%-theme(spacing[10]))]' />
		);
	}

	if (size === 'lg') {
		return (
			<KHCLottie lottieStyle='absolute w-24 h-24 left-[calc(50%-theme(spacing[12]))] top-[calc(50%-theme(spacing[12]))]' />
		);
	}
}
