import Lottie from 'lottie-react';
import { useEffect, useRef } from 'react';
import Loading from 'assets/lottie-loading.json';
import Hourglass from 'assets/lottie-hourglass.json';
import classNames from 'classnames';

export default function KHCLottie({ isFullPage, containerStyle, lottieStyle, speed = 2, animationData }) {
	const lottieRef = useRef();
	animationData = animationData === 'hourglass' ? Hourglass : Loading

	useEffect(() => {
		lottieRef.current.setSpeed(speed);
	}, []);

	return (
		<>
			{isFullPage ? (
				<div className={classNames('w-screen h-screen', containerStyle)}>
					<Lottie
						lottieRef={lottieRef}
						animationData={animationData}
						className={classNames('h-full w-1/4 m-auto max-w-[100px]', lottieStyle)}
					/>
				</div>
			) : (
				<Lottie
					lottieRef={lottieRef}
					animationData={animationData}
					className={classNames('', lottieStyle)}
				/>
			)}
		</>
	);
}
