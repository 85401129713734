import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Wrapper = ({children}) => {

  const location = useLocation()

  return children
}

export default Wrapper