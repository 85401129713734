import React from 'react'
import classnames from 'classnames';

const Button = (props) => {
  return (
    <button 
        onClick={props.onClick}
        className={classnames('btn', props.className)}
        disabled={props?.disabled}
        >
            {props.label}
        </button>
  )
}

export default Button