import React from 'react'
import classnames from "classnames"

function TextArea(props) {

    return (
        <div className="form-group">
            {props.label && <label>{props.label}</label>}
            <div>
                <textarea
                    className={classnames("form-control ",
                        {
                            "has-error": props.error
                        })}
                    style={{ "height": "90%" }}
                    readOnly={props.readonly}
                    name={props.name}
                    rows={props.rows || 8}
                    value={props.value}
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                />
            </div>
            {
                props.error && (
                    <span className="error text-secondary-500 font-avenir" style={{ fontSize: 11 }}>{props.error}</span>
                )
            }
        </div>
    );
}

export default TextArea;
