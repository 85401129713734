import * as signalR from "@microsoft/signalr"
import React from "react";
import { constants } from "./webclient";
import { store, logout } from "utils/redux-store"
import Swal from "sweetalert2"
import { publish } from "./global-events";

class ISessionSocket {

    constructor(intl) {
        this.connection = null
        this.intl = intl
    }

    Connect() {

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${constants.baseURL}/sessionHub`)
            .configureLogging(signalR.LogLevel.Information)
            .build();
    
        this.connection.on("ForceDisconnect", (message) => {
            this.connection.stop();
        });

        // this.connection.on("ConnectionResult", (message) => {
        //     const { member } = store.getState()
        //     this.connection.invoke("SessionLogin", member)
        // });

        this.connection.on("SessionKill", (sessionId) => {

            console.log("SESSION KILL -----> ", sessionId)

            const { member } = store.getState()
            if (member?.sessionId == sessionId) {
                Swal.fire({
                    title: this.intl.formatMessage({id: "warning", defaultMessage: "warning"}),
                    text: this.intl.formatMessage({id: "warning_session_dropped", defaultMessage: "warning_session_dropped"}),
                    icon: "warning",
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: this.intl.formatMessage({id: "ok", defaultMessage: "ok"}),
                    cancelButtonText: "",
                    denyButtonText: "",
                    reverseButtons: true,
                    customClass: {
                        confirmButton: 'btn btn-success'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    logout()
                }) 
            }           
        });  
        
        this.connection.on("DashboardChange", (profileId) => {
            console.log("DashboardChange")
            const patient = store.getState().member?.profileId ?? 0
            if (profileId == patient) {
                publish("onDashboardRefresh")
            }
        });          
                
        this.connection.start().then((a) => {}).catch((error) => {});
    }

    Disconnect() {
        this.connection.stop()
    }

}

export default ISessionSocket