import React, { useContext } from 'react'
import Select, { createFilter } from "react-select"
import { AppContext } from 'configuration/app-context'

export const turkishCharacterRegex = keyword => keyword
    .replace(/[ıİiI]/g, '[ıİiI]')
    .replace(/[şŞsS]/g, '[şŞsS]')
    .replace(/[çÇcC]/g, '[çÇcC]')
    .replace(/[ğĞgG]/g, '[ğĞgG]')
    .replace(/[öÖoO]/g, '[öÖoO]')
    .replace(/[üÜuU]/g, '[üÜuU]');


const customFilter = (option, searchText) => ((turkishCharacterRegex(option.data.label)).toLowerCase().includes(turkishCharacterRegex(searchText).toLowerCase()));

const CustomSelect = (props) => {
    const { translate } = useContext(AppContext)

    return (
        <div className="form-group">
            {props.label && <label>{props.label}</label>}
            {props?.isSearchable ? (
                <Select
                    styles={{ ...style, ...props.styles }}
                    classNamePrefix={props.error ? "select-err" : "select"}
                    placeholder={(props.placeholder)}
                    onChange={(e) => { props.onChange && props.onChange(e) }}
                    value={props.value}
                    defaultValue={props.defaultValue}
                    formatOptionLabel={props.formatOptionLabel}
                    components={props.components}
                    isMulti={props?.multi ? true : false}
                    isSearchable={true}
                    filterOption={customFilter}
                    onInputChange={props.onInputChange}
                    name={props.name}
                    options={props.options}
                    noOptionsMessage={() => `${translate("no_options")}`}
                    onBlur={props.onBlur}
                    isDisabled={props.isDisabled}
                    menuIsOpen={props.menuIsOpen}
                    onMenuOpen={props.onMenuOpen}
                    isOptionDisabled={props?.isOptionDisabled ? props.isOptionDisabled : () => {}}
                    menuPlacement='auto'
                    className={props.className}
                />
            ) : (
                <Select
                    styles={{ ...style, ...props.styles }}
                    classNamePrefix={props.error ? "select-err" : "select"}
                    placeholder={(props.placeholder)}
                    onChange={(e) => { props.onChange && props.onChange(e) }}
                    value={props.value}
                    defaultValue={props.defaultValue}
                    formatOptionLabel={props.formatOptionLabel}
                    components={props.components}
                    isMulti={props?.multi ? true : false}
                    isSearchable={false}
                    name={props.name}
                    options={props.options}
                    noOptionsMessage={() => `${translate("no_options")}`}
                    onBlur={props.onBlur}
                    isDisabled={props.isDisabled}
                    menuIsOpen={props.menuIsOpen}
                    onMenuOpen={props.onMenuOpen}
                    isOptionDisabled={props?.isOptionDisabled ? props.isOptionDisabled : () => {}}
                    menuPlacement='auto'
                    className={props.className}
                />
            )}

            {props.showErrorLabel && props.error && (
                <span className="font-normal error text-secondary-500" style={{ fontSize: 11 }}>
                    {props.error}
                </span>
            )}
        </div>
    );
}

export default CustomSelect

const style = {
    control: (base) => ({
        ...base,
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: '#6A7173 !important',
        borderRadius: '12px',
        padding: '3px 4px',
        border: '1px solid #E4E4E4 !important',
        cursor: 'pointer'
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: '#0F1F77'
    })
}