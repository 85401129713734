import React from 'react'
import classnames from 'classnames';

const IconButton = (props) => {
    return (
        <button
            style={props.style}
            onClick={props.onClick}
            disabled={props?.disabled ?? false}
            className={classnames('btn flex items-center space-x-2', props.className)}>
            {props.children}
            <label className="pointer-events-none">{props.label}</label>
        </button>
    )
}

export default IconButton