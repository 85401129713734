import React from 'react';
import ReactDOM from 'react-dom/client'
import App from "project"
import reportWebVitals from 'tests/reportWebVitals'
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { IntlContextProvider } from 'configuration/intl-context'
import { AppContextProvider } from 'configuration/app-context'
import { ThemeContextProvider } from 'configuration/theme-context'
import { store } from 'utils/redux-store';

import 'react-perfect-scrollbar/dist/css/styles.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "flatpickr/dist/themes/light.css";
import "react-toastify/dist/ReactToastify.css"
import "video-react/dist/video-react.css";
import 'sweetalert2/src/sweetalert2.scss'
import "react-toggle/style.css"
import "assets/css/style.scss"
import "assets/css/style2.scss"

import { ToastContainer } from 'react-toastify';

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

if (process.env.NODE_ENV !== "development") {
    console.log = function () { }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider>
        <Provider store={store}>
            <ThemeContextProvider>
                <IntlContextProvider>
                    <AppContextProvider>
                        <App />
                        <ToastContainer />
                    </AppContextProvider>
                </IntlContextProvider>
            </ThemeContextProvider>
        </Provider>
    </CookiesProvider>
);


reportWebVitals();