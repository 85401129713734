/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import { AuthRouter, MainRouter, CodeLotusRouter } from "configuration/router"
import { useSelector } from 'react-redux'
import WebClient from 'utils/webclient'
import FullPageSpinner from 'components/spinner/fullpage-spinner'
import * as Redux from "utils/redux-store"
import { BasketService } from 'utils/api-service'
import { useCookies } from 'react-cookie';
import { subscribe } from 'utils/global-events'
import OneSignal from 'react-onesignal'
import { useIntl } from 'react-intl'
import VideoCallWindow from "./views/pages/video-call/video-call-window"
import IBigSpinner from 'components/spinner/big-spinner'
import { ThemeContext } from 'configuration/theme-context';
import Modal from 'components/modal'
import ReactGA from "react-ga4";
import { AppContext } from 'configuration/app-context'
import RatingForm from 'components/rating/RatingForm'
ReactGA.initialize("G-KNFS20LB1X");

async function initIntl(cookies) {
    // await Redux.initIntl(intl)
    await SetLanguage()
    await Welcome()

    if (cookies?.member && cookies?.patient) {
        Redux.setPatientData(cookies.patient)
        Redux.setMemberData(cookies.member)
        Redux.ConnectSessionSocket()
        /*         new BasketService().getSummary() */
    }
}

async function SetLanguage() {

    console.log("HREF => ", window.location.href)
    console.log("PATHNAME => ", window.location.pathname)

    let lang = "TR"
    const language = await localStorage.getItem("language")
    if (language !== null) lang = language
    Redux.setLanguage(lang)

    let langId = 1
    const languageId = await localStorage.getItem("languageId")
    if (languageId !== null) langId = languageId
    Redux.setLanguageId(langId)
}

async function Welcome() {
    let at = await localStorage.getItem("access_token")

    if (at !== null) {
        Redux.setTokenObject({
            expireDate: localStorage.getItem("expireDate"),
            token: at
        })
    }

    let client = new WebClient()
    await client.welcome()
}

async function initOneSignal(translate) {
    // unsupported ise OneSignal calışmıyor. Alt satıra geçmiyor.
    await OneSignal.init({
        //  uat      appId: "138474e6-a84b-49f2-aca8-abfdbb8b1f54", safari_web_id: "web.onesignal.auto.2d55a779-27e1-4bb7-a896-7e19803bedb7"
        //  local    appId: "e9f68d63-3fe1-4013-a5b0-6b6e4b6a0741", safari_web_id: "web.onesignal.auto.3d9f0610-6ae1-419f-862e-705396ff3ef1"
        appId: '138474e6-a84b-49f2-aca8-abfdbb8b1f54',
        allowLocalhostAsSecureOrigin: true,
        safari_web_id: 'web.onesignal.auto.2d55a779-27e1-4bb7-a896-7e19803bedb7',
        notifyButton: {
            enable: true /* Required to use the Subscription Bell */,
            /* SUBSCRIPTION BELL CUSTOMIZATIONS START HERE */
            size: 'medium' /* One of 'small', 'medium', or 'large' */,
            theme: 'default' /* One of 'default' (red-white) or 'inverse" (white-red) */,
            position: 'bottom-right' /* Either 'bottom-left' or 'bottom-right' */,
            offset: {
                bottom: '0px',
                left: '0px' /* Only applied if bottom-left */,
                right: '0px' /* Only applied if bottom-right */,
            },
            showCredit: false /* Hide the OneSignal logo */,
            text: {
                'tip.state.unsubscribed': translate('onesignal_notify_tip_state_unsubscribed'),
                'tip.state.subscribed': translate('onesignal_notify_tip_state_subscribed'),
                'tip.state.blocked': translate('onesignal_notify_tip_state_blocked'),
                'message.prenotify': translate('onesignal_notify_message_prenotify'),
                'message.action.subscribed': translate('onesignal_notify_message_action_subscribed'),
                'message.action.resubscribed': translate('onesignal_notify_message_action_resubscribed'),
                'message.action.unsubscribed': translate('onesignal_notify_message_action_unsubscribed'),
                'dialog.main.title': translate('onesignal_notify_dialog_main_title'),
                'dialog.main.button.subscribe': translate('onesignal_notify_dialog_main_button_subscribe'),
                'dialog.main.button.unsubscribe': translate('onesignal_notify_dialog_main_button_unsubscribe'),
                'dialog.blocked.title': translate('onesignal_notify_dialog_main_blocked_title'),
                'dialog.blocked.message': translate('onesignal_notify_dialog_main_blocked_message'),
            },
            colors: {
                // Customize the colors of the main button and dialog popup button
                'circle.background': 'rgb(84,110,123)',
                'circle.foreground': 'white',
                'badge.background': 'rgb(84,110,123)',
                'badge.foreground': 'white',
                'badge.bordercolor': 'white',
                'pulse.color': 'white',
                'dialog.button.background.hovering': 'rgb(77, 101, 113)',
                'dialog.button.background.active': 'rgb(70, 92, 103)',
                'dialog.button.background': 'rgb(84,110,123)',
                'dialog.button.foreground': 'white',
            },
            /* HIDE SUBSCRIPTION BELL WHEN USER SUBSCRIBED */
            displayPredicate: function () {
                return OneSignal.isPushNotificationsEnabled().then(function (isPushEnabled) {
                    return !isPushEnabled;
                });
            },
        },
        promptOptions: {
            slidedown: {
                prompts: [
                    {
                        type: "push", // current types are "push" & "category"
                        autoPrompt: true,
                        text: {
                            /* limited to 90 characters */
                            actionMessage: translate('onesignal_slidedown_message'),
                            /* acceptButton limited to 15 characters */
                            acceptButton: translate('onesignal_slidedown_accept'),
                            /* cancelButton limited to 15 characters */
                            cancelButton: translate('onesignal_slidedown_cancel')
                        },
                        delay: {
                            pageViews: 3,
                            timeDelay: 20
                        }
                    }
                ]
            },
            customlink: {
                enabled: true /* Required to use the Custom Link */,
                style: 'button' /* Has value of 'button' or 'link' */,
                size: 'medium' /* One of 'small', 'medium', or 'large' */,
                color: {
                    button: '#E12D30' /* Color of the button background if style = "button" */,
                    text: '#FFFFFF' /* Color of the prompt's text */,
                },
                text: {
                    subscribe: translate('onesignal_customlink_subscribe') /* Prompt's text when not subscribed */,
                    unsubscribe: translate('onesignal_customlink_unsubscribe') /* Prompt's text when subscribed */,
                    explanation: translate('onesignal_customlink_explanation') /* Optional text appearing before the prompt button */,
                },
                unsubscribeEnabled: false /* Controls whether the prompt is visible after subscription */,
            },
        },
    });
}

const App = () => {

    const intl = useIntl()
    const { translate } = useContext(AppContext)
    const [loading, setLoading] = useState(true)
    const [cookies, removeCookie] = useCookies(['member', 'patient']);
    const patient = useSelector(state => state.patient)
    const videoCall = useSelector(state => state.videoCall)
    const videoCallSurvey = useSelector(state => state.videoCallSurvey)
    const pageLoading = useSelector(state => state.loading)
    const { theme, setTheme, toggleTheme } = useContext(ThemeContext)

    subscribe("onClearCookie", () => {
        removeCookie("member")
        removeCookie("patient")
    })

    useEffect(() => {
        Redux.InitSessionSocket(intl)

        initIntl(cookies)
            .then(() => {
                setLoading(false)
            })
            .catch(() => { setLoading(false) })
    }, [])

    useEffect(() => {
        theme == "theme-codeLotus" ?
            document.body.classList.add('theme-codeLotus')
            :
            document.body.classList.remove('theme-codeLotus')
    }, [theme])

    if (loading) return <FullPageSpinner />
    return patient == null || patient === "undefined" ? <AuthRouter /> :

        <div>
            {
                theme == "theme-codeLotus" ? (
                    <CodeLotusRouter />
                ) : (
                    <MainRouter />
                )
            }

            {
                pageLoading && (
                    <div className="overlay">
                        <IBigSpinner size='sm' />
                    </div>
                )
            }

            {
                videoCall != null && (
                    <VideoCallWindow
                        appointmentId={videoCall?.id}
                        videoCallUrl={videoCall?.url}
                        setVideCallUrl={() => {
                            Redux.setVideoCallState(null)
                        }}
                        doctorCode={videoCall?.doctorCode}
                        appointmentDate={videoCall?.appointmentDate} />)
            }

            {
                videoCallSurvey != null && (
                    <Modal
                        bodyClassName='w-auto'
                        isShowButtons={false}
                        isShow={true}>
                        <RatingForm />
                    </Modal>
                )
            }
        </div>
}

export default App