import "assets/css/radiobox.scss"
const classNames = require("classnames");

export default function IRadioBox(props) {
    return (
        <div 
            className={classNames("radiobox-cont ", props.className, {"checked": props?.value})}
            onClick={() => props.onChange()}
        >
            <div className="box"></div>
            
            {
            props?.label && (
            <span className={props.className}>{props.label}</span>
            )
            }

        </div>
    )
}