import React from 'react'
import classnames from 'classnames';

const OnlyIconButton = (props) => {
    return (
        <button type={props?.type ?? 'button'}
            onClick={props.onClick}
            className={classnames('btn btn-icon', props.className)}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    )
}

export default OnlyIconButton